<template>
  <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5">
    <slot name="default"></slot>

    <div class="sm:w-60 w-full mt-5 mx-auto">
      <Button
        class="mt-3 text-sm h-11 lg:text-lg"
        color="sky"
        @click="$emit('setSettings')"
        :disabled="loading != false"
      >
        ثبت
      </Button>
    </div>

    <hr
      class="my-3 border-blueGray-200"
      v-if="otherSetting.length > 1 || otherGroup.length || $slots.help"
    />

    <!-- ANCHOR helper -->

    <div
      v-if="$slots.help"
      class="mb-3 bg-blueGray-50 border border-amber-500 rounded-md border-t-4 p-3"
      v-show="help"
    >
      <slot name="help" />
    </div>

    <!-- ANCHOR pro Settings -->

    <div
      class="mb-3 bg-blueGray-50 border border-red-500 rounded-md border-t-4 p-3"
      v-show="pro"
      v-if="otherSetting.length > 1 || otherGroup.length"
    >
      <p>
        از این قسمت می‌توانید تنظیمات این قسمت را بر روی تنظیمات سایر دسته ها و حتی
        تنظیمات سایر گروه ها اعمال کنید
      </p>
      <div class="grid sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
        <div v-if="otherSetting.length > 1">
          <MnrMultiSelect
            title="سایر دسته‌بندی ها"
            v-model="settingsId"
            :options="otherSetting"
            :showTags="true"
            oValue="id"
            oText="name"
            placeholder="انتخاب کنید"
            :someText="settingsId.length + ' مورد'"
          ></MnrMultiSelect>
          <Button
            class="mt-3 text-sm h-11 lg:text-lg"
            color="sky"
            @click="$emit('setSettings', settingsId)"
            v-show="settingsId.length"
            :disabled="loading != false"
          >
            اعمال بر دسته‌ها
          </Button>
        </div>
        <div v-if="otherGroup.length">
          <MnrMultiSelect
            title="تنظیمات سایر گروه ها"
            v-model="groupsId"
            :options="otherGroup"
            :showTags="true"
            oValue="id"
            oText="name"
            oHint="group"
            placeholder="انتخاب کنید"
            :someText="groupsId.length + ' مورد'"
          ></MnrMultiSelect>
          <Button
            v-show="groupsId.length"
            class="mt-3 text-sm h-11 lg:text-lg"
            color="sky"
            @click="$emit('setSettings', groupsId)"
            :disabled="loading != false"
          >
            اعمال بر گروه‌ها
          </Button>
        </div>
      </div>
    </div>

    <!-- ANCHOR buttons -->

    <div class="flex gap-2">
      <Button
        class="inline-flex text-sm h-8"
        color="amber"
        @click="help = !help"
        v-if="$slots.help"
      >
        راهنما
      </Button>
      <Button
        class="inline-flex text-sm h-8"
        color="red"
        @click="pro = !pro"
        v-if="otherSetting.length > 1 || otherGroup.length"
      >
        گزینه های پیشرفته
      </Button>
    </div>
  </div>
</template>

<script>
import MnrMultiSelect from "./mnr/MnrMultiSelect.vue";
import Button from "./Button.vue";

export default {
  components: { MnrMultiSelect, Button },
  props: {
    otherSetting: {
      type: Array,
      default: function () {
        return [];
      },
    },
    otherGroup: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      help: false,
      pro: false,
      settingsId: [],
      groupsId: [],
    };
  },
};
</script>
