<template>
  <ul
    v-if="Object.keys(errors).length"
    id="error-box"
    class="list-inside list-disc bg-red-200 p-3 border-red-500 border-r-4 text-red-800"
  >
    <li v-for="(item, index) in errors" :key="index">
      {{ typeof item === "object" ? item[0] : item }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>
