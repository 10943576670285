<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-adjustment-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">تنظیمات کاربران</div>
      </div>
      <h2 class="text-xl font-bold">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <ErrorBox :errors="errors" class="mb-5" />
      <div class="p-3 bg-blueGray-200 border border-blueGray-300 rounded-md">
        <div class="text-xl font-bold">توجه کنید:</div>
        <p class="mt-3">
          شما برای هر گروه می‌توانید دسته‌بندی های مختلف با تنظیمات مختلفی ایجاد کنید. هر
          گروه یک تنظیمات پیشفرض دارد اما میتوانید دسته هایی با نام های دیگر هم اضافه کنید
          و کاربران را در دسته های مختلف جدا سازی کرده و برای هر دسته تنظیمات مورد نظر را
          اعمال کنید. اگر به این قابلیت نیازی ندارید این قسمت را نادیده بگیرید
        </p>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            v-model="parts.setting.value"
            :options="otherSetting"
            oValue="id"
            oText="name"
            placeholder="تنظیمات مورد نظر را انتخاب کنید"
            @input="changeSetting"
          ></MnrSelect>
          <Button
            :to="{
              name: 'GroupOwnerSettingBranchs',
              params: {
                id: $store.state.user.id,
                groupId: group.id,
              },
            }"
            class="text-sm lg:text-lg"
            color="teal"
          >
            اضافه کردن دسته جدید
          </Button>
          <!-- <div>
            <Button
              class="text-sm lg:text-lg"
              color="teal"
              @click="parts.setting.showAdd = !parts.setting.showAdd"
            >
              اضافه کردن دسته جدید
            </Button>
            <div class="mt-3" v-show="parts.setting.showAdd">
              <mnr-text placeholder="یک نام دلخواه برای دسته وارد کنید" />
              <Button class="mt-3 text-sm h-11 lg:text-lg" color="sky">ثبت</Button>
              <p>
                میتوانید بعدا از قسمت
                <b>مدیریت دسته‌بندی‌ها</b>
                این نام را ویرایش کنید
              </p>
            </div>
          </div> -->
        </div>
      </div>

      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5">
        <div class="text-xl font-bold">راهنمای گزینه ها</div>
        <ul class="my-5">
          <li>{{ options[0].text }}: تنظیمات مورد نظر غیر فعال است</li>
          <li>
            {{ options[1].text }}: پیام را حذف کرده و به کاربر دلیل حذف پیام را می‌گوید
          </li>
          <li>
            {{ options[2].text }}: پیام را حذف کرده و به کاربر دلیل حذف پیام را به اضافه
            یک اخطار به آن کاربر می‌گوید. اگر سیستم اخطار خاموش باشد این گزینه با گزینه
            بالا تفاوتی ندارد
          </li>
          <li>
            {{ options[3].text }}: پیام را حذف کرده و کاربر مورد نظر را تا زمانی که در
            قسمت تنظیمات گروه مشخص کرده اید محدود میکند
          </li>
          <li>
            {{ options[4].text }}: پیام را حذف کرده و کاربر مورد نظر را تا زمانی که در
            قسمت تنظیمات گروه مشخص کرده اید اخراج میکند
          </li>
          <li>
            {{ options[5].text }}: پیام را حذف کرده و کاربر مورد نظر را برای همیشه محدود
            میکند
          </li>
          <li>
            {{ options[6].text }}: پیام را حذف کرده و کاربر مورد نظر را برای همیشه اخراج
            میکند
          </li>
        </ul>
        <div class="text-xl font-bold">توجه:</div>
        <ul class="list-inside list-disc">
          <li>
            در استفاده از گزینه های "{{ options[4].text }}" و "{{ options[6].text }}"
            احتیاط کنید. این گزینه ها به جز در موارد خاص توسط سازنده پیشنهاد نمی‌شود.
          </li>
          <li>
            در هر قسمت میتوانید با زدن دکمه راهنما. راهنمای آن قسمت را به علاوه پیشنهاد
            سازنده برای تنظیمات را مطالعه کنید.
          </li>
        </ul>
      </div>
      <!-- ANCHOR global defender -->

      <!-- items -> global,no_bad_user,no_global_spam -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('global', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-module-96.png" class="inline-block h-20" />
          امنیت سراسری
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <div>
            <MnrSelect
              title="کاربران مخرب"
              v-model="setting.no_bad_user"
              :options="
                L_.filter(options, (o) => {
                  return ['off', 'ban', 'kick', 'ban_ever', 'kick_ever'].includes(
                    o.value
                  );
                })
              "
              placeholder="تنظیمات مورد نظر را انتخاب کنید"
            ></MnrSelect>
          </div>
          <div>
            <MnrSelect
              title="اسپم سراسری"
              v-model="setting.no_global_spam"
              :options="
                L_.filter(options, (o) => {
                  return [
                    'off',
                    'delete',
                    'alert',
                    'ban',
                    'kick',
                    'ban_ever',
                    'kick_ever',
                  ].includes(o.value);
                })
              "
              placeholder="انتخاب کنید"
            ></MnrSelect>
          </div>
        </div>

        <template #help>
          <h2 class="text-lg font-bold">کاربران مخرب</h2>
          <p class="mt-2">
            ربات کاربران مخربی که در گروه های دیگر شناسایی کرده را محدود یا اخراج می‌کند.
            کاربرانی که در گروه های دیگر ربات اضافه کرده اند یا به عنوان ربات تبچی شناسایی
            شده اند
          </p>
          <h2 class="text-lg font-bold mt-3">اسپم سراسری</h2>
          <p class="mt-2">
            ربات پیام هایی که در همه گروه ها زیاد تکرار شده باشد را شناسایی و حذف می‌کند
          </p>
          <h2 class="text-lg font-bold mt-3">پیشنهاد تنظیمات توسط سازنده</h2>
          <p>
            بهتر است گزینه کاربران مخرب روی محدود شود باشد. و در مورد گزینه اسپم سراسری
            اگر گروه شما تبلیغاتی است بهتر است خاموش باشد. در غیر این صورت در گروه های
            تخصصی و پرسش و پاسخ روی گزینه اخطار و حذف پیام باشد
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR im not bot -->
      <!-- items -> bot_checker,no_bad_user -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('bot_checker', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-facial-recognition-96.png"
            class="inline-block h-20"
          />
          تایید هویت کاربران
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            v-model="setting.bot_checker"
            :options="
              L_.filter(options, (o) => {
                return ['off', 'delete', 'alert'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
        </div>

        <template #help>
          <p class="mt-2">
            این گزینه برای گروه هایی که عضویت اجباری یا دعوت اجباری آنها فعال نیست و ربات
            های تبچی میتوانند پیام ارسال کنند مناسب است. ربات از کاربران میخواهد که قبل از
            ارسال پیام حتما با زدن دکمه من ربات نیستم مشخص کنند که ربات نیستند. اگر در
            گروه خود دعوت اجباری را فعال کرده اید این گزینه نیاز نیست روشن باشد.
          </p>
          <h2 class="text-lg font-bold mt-3">پیشنهاد تنظیمات توسط سازنده</h2>
          <p>
            اگر در گروه شما دعوت اجباری فعال است این گزینه بهتر است خاموش باشد. در غیر این
            صورت بهتر است روی گزینه اخطار و حذف پیام باشد
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR filter user name -->
      <!-- items -> filter_name,bad_name -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('filter_name', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-more-info-skin-type-7-96.png"
            class="inline-block h-20"
          />
          فیلتر نام کاربران
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            title="فیلتر نام نامناسب"
            v-model="setting.bad_name"
            :options="
              L_.filter(options, (o) => {
                return ['off', 'ban', 'kick', 'ban_ever', 'kick_ever'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="فیلتر نام دلخواه"
            v-model="setting.filter_name"
            :options="
              L_.filter(options, (o) => {
                return ['off', 'ban', 'kick', 'ban_ever', 'kick_ever'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
        </div>

        <div class="grid xl:grid-cols-3 grid-cols-2 gap-3 mt-3">
          <MnrMultiText
            v-model="filters_name.filterLow"
            title="فیلتر ساده"
            placeholder="با اینتر جدا کنید"
            hint="کلمات را نوشته و اینتر بزنید"
            :disabled="setting.filter_name == 'off'"
          ></MnrMultiText>
          <MnrMultiText
            v-model="filters_name.filterAvg"
            title="فیلتر متوسط"
            placeholder="با اینتر جدا کنید"
            hint="کلمات را نوشته و اینتر بزنید"
            :disabled="setting.filter_name == 'off'"
          ></MnrMultiText>
          <MnrMultiText
            v-model="filters_name.filterPro"
            title="فیلتر پیشرفته"
            placeholder="با اینتر جدا کنید"
            hint="کلمات را نوشته و اینتر بزنید"
            :disabled="setting.filter_name == 'off'"
          ></MnrMultiText>
        </div>

        <template #help>
          <h2 class="text-lg font-bold mt-3">فیلتر نام نامناسب</h2>
          <p class="mt-2">
            در این قسمت انتخاب میکنید که کاربرانی که نام نا مناسب دارند موقع ارسال پیام یا
            ورود به گروه شناسایی و بسته به تنظیمات شما محدود یا حذف شوند.
          </p>
          <h2 class="text-lg font-bold mt-3">فیلتر نام دلخواه</h2>
          <p>
            میتوانید فیلتر نام دلخواه را فعال کرده و کلماتی که میخواهید فیلتر شوند را در
            قسمت های فیلتر ساده، فیلتر متوسط و فیلتر پیشرفته مشخص کنید. پیشنهاد می‌شود از
            فیلتر ساده استفاده کنید.
          </p>
          <h2 class="text-lg font-bold mt-3">پیشنهاد تنظیمات توسط سازنده</h2>
          <p>
            به دلیل این که ربات ممکن است اشتباه کند بهتر است این گزینه روی محدود شود باشد
            و شما با فعال کردن سیستم گزارش در قسمت تنظیمات گروه می‌توانید بررسی کنید و اگر
            ربات درست تشخیص داده بود خودتان آن شخص را اخراج کنید.
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR remove bot -->
      <!-- items -> remove_bot,remove_bot,remove_bot_adder -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('remove_bot', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-c-3po-96.png" class="inline-block h-20" />
          ربات های مزاحم
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            title="ربات های مزاحم"
            v-model="setting.remove_bot"
            :options="
              L_.filter(options, (o) => {
                return ['off', 'ban', 'kick', 'ban_ever', 'kick_ever'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="دعوت کننده ربات"
            v-model="setting.remove_bot_adder"
            :options="
              L_.filter(options, (o) => {
                return ['off', 'alert', 'ban', 'kick', 'ban_ever', 'kick_ever'].includes(
                  o.value
                );
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
        </div>

        <p class="mt-5">
          برای اینکه به طور کامل از شر ربات های مزاحم خلاص شوید به ربات دسترسی اضافه کردن
          ادمین جدید بدهید و دستور
          <bdi>/remove_bots</bdi>
          را در گروه بزنید. با این کار ربات کمکی وارد گروه شده و به طور خودکار ادمین
          می‌شود و ربات هایی را به گروه اضافه میکند تا ظرفیت ربات ها پر شده و دیگر رباتی
          به گروه وارد نشود. در حال حاظر این بهترین و تنها راه برای جلوگیری از ربات های
          مزاحم است
        </p>

        <template #help>
          <p class="mt-2">
            در این قسمت می‌توانید انتخاب کنید رباتهای مزاحم و کسانی که آنها را به گروه شما
            اضافه می‌کنند محدود یا حذف شوند. ادمین ها شامل این محدودیت نمی‌شوند.
          </p>
          <h2 class="text-lg font-bold mt-3">پیشنهاد تنظیمات توسط سازنده</h2>
          <p>
            بهتر است هر دو این گزینه ها بر روی اخراج برای همیشه باشد. دقت کنید ادمین های
            گروه شامل این گزینه ها نمی‌شوند
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR locks -->
      <!-- items -> locks -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('locks', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-lock-96.png" class="inline-block h-20" />
          قفل ها
        </div>

        <div
          class="
            grid
            2xl:grid-cols-4
            xl:grid-cols-3
            sm:grid-cols-2
            grid-cols-1
            gap-3
            mt-3
          "
        >
          <MnrSelect
            title="قفل کامل گروه"
            v-model="setting.lock"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل عکس"
            v-model="setting.no_photo"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل فیلم"
            v-model="setting.no_video"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل استیکر"
            v-model="setting.no_sticker"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل آهنگ"
            v-model="setting.no_music"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل صدا"
            v-model="setting.no_voice"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل گیف"
            v-model="setting.no_gif"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل فایل"
            v-model="setting.no_file"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل بازی"
            v-model="setting.no_game"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل ویدیو نوت"
            v-model="setting.no_video_note"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل مخاطب"
            v-model="setting.no_contact"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل شماره تلفن و موبایل"
            v-model="setting.no_phone_number"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل لوکیشن"
            v-model="setting.no_location"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل متن"
            v-model="setting.no_text"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل شکلک (اموجی)"
            v-model="setting.no_emoji"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل شکلک (اموجی) بدون متن"
            v-model="setting.no_just_emoji"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل کلید شیشه ای"
            v-model="setting.no_inline_key"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="قفل برنامه اندروید"
            v-model="setting.no_apk"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
        </div>

        <template #help>
          <p class="mt-2">
            از این قسمت می‌توانید مواردی که نمیخواهید در گروه شما ارسال شوند را قفل کنید.
          </p>
          <h2 class="text-lg font-bold mt-3">پیشنهاد تنظیمات توسط سازنده</h2>
          <p>
            بهتر است مواردی که نمیخواهید به گرو شما ارسال شوند را بر روی حذف پیام یا اخطار
            و حذف پیام قرار دهید
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR remove link-->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('remove_link', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-delete-link-96.png"
            class="inline-block h-20"
          />
          حذف لینک
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            title="لینک های داخلی"
            hint="لینک کانال ها و گروه‌های تلگرام"
            v-model="setting.no_telegram_link"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                  'pro',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="حذف لینک ‌های خارجی"
            hint="لینک سایت ها موارد دیگر"
            v-model="setting.no_out_link"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                  'pro',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="فوروارد از کانال"
            hint="پیام های ارسال شده از کانال ها"
            v-model="setting.no_forward"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                  'pro',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="فوروارد از شخص"
            hint="پیام های ارسال شده از طرف شخص دیگر"
            v-model="setting.no_person_forward"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                  'pro',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="حذف یوزرنیم"
            hint="پیام هایی که شامل یوزرنیم است"
            v-model="setting.no_username"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                  'pro',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="روش حذف پیشرفته"
            hint="فقط در صورتی که از حذف پیشرفته استفاده کرده باشید"
            v-model="setting.sender_key"
            :options="
              L_.filter(options, (o) => {
                return ['off', 'in_text', 'in_key'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
        </div>

        <template #help>
          <h2 class="text-lg font-bold">حذف لینک</h2>
          <p class="mt-2">
            از این قسمت میتوانید لینکهای ارسالی به گروه خود یا سایر موارد را حذف کنید
          </p>
          <h2 class="text-lg font-bold mt-3">حذف لینک پیشرفته</h2>
          <p class="mt-2">
            اگر نمیدانید حذف لینک پیشرفته چطور کار می‌کند آنرا فعال نکنید. این قابلیت برای
            گروه های کوچک و دوستانه مناسب است که نمیخواهند لینک کانال های دیگر در آن تبلیغ
            شود ولی خود پیام هم حذف نشود. ربات پیام های حاوی لینک را حذف میکند و آن را
            بدون لینک دوباره ارسال می‌کند. همراه با نام ارسال کننده
          </p>
          <h2 class="text-lg font-bold mt-3">پیشنهاد تنظیمات توسط سازنده</h2>
          <p>
            بهتر است مواردی که نمیخواهید به گرو شما ارسال شوند را بر روی حذف پیام یا اخطار
            و حذف پیام قرار دهید
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR add user -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('add_user', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-add-user-group-woman-man-96.png"
            class="inline-block h-20"
          />
          دعوت اجباری
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            title="دعوت برای پیام"
            v-model="setting.add_force"
            :options="
              L_.filter(options, (o) => {
                return ['off', 'delete', 'alert'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrNumber
            v-model="setting.add_for_pm"
            title="تعدا افراد"
            inputDir="ltr"
            before="نفر"
            placeholder="انتخاب کنید"
            :disabled="setting.add_force == 'off'"
          ></MnrNumber>
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            title="دعوت برای لینک"
            v-model="setting.add_force_link"
            :options="
              L_.filter(options, (o) => {
                return ['off', 'delete', 'alert'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrNumber
            v-model="setting.add_for_link"
            title="تعدا افراد"
            inputDir="ltr"
            before="نفر"
            placeholder="انتخاب کنید"
            :disabled="setting.add_force_link == 'off'"
          ></MnrNumber>
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <div>
            <MnrCheckSlider v-model:checked="setting.add_reset">
              دعوت دوباره
            </MnrCheckSlider>
            <MnrNumber
              v-model="setting.add_day_reset"
              inputDir="ltr"
              hint="درخواست دوباره دعوت بعد از زمان مشخص"
              before="روز"
              placeholder="انتخاب کنید"
              :disabled="!setting.add_reset"
            ></MnrNumber>
          </div>
        </div>
        <div class="mt-3">
          <MnrCheckSlider v-model:checked="setting.add_repeat">
            شمارش دعوت تکراری
          </MnrCheckSlider>
          <br />
          <MnrCheckSlider v-model:checked="setting.add_just_new">
            دعوت فقط برای افرادی که بعد از ربات وارد گروه شده اند
          </MnrCheckSlider>
        </div>

        <template #help>
          <p class="mt-2">
            از این قسمت میتوانید دعوت اجباری را مدیریت کنید. دقت کنید که اگر دعوت برای
            لینک کمتر از دعوت برای پیام باشد تاثیری نخواهد داشت. همچنین می‌توانید تعداد
            روزی که دوباره کاربران باید بعد از آن دعوت کنند را هم مشخص کنید.
          </p>
          <h2 class="text-lg font-bold mt-3">دعوت فقط برای افراد جدید</h2>
          <p>
            دعوت فقط برای افرادی که بعد از ربات وارد گروه شده‌اند. این گزینه برای گروه های
            مناسب است که قبلا از ربات دیگری استفاده میکردند و نمیخواهند کاربرانی که قبلا
            دعوت کرده اند دوباره دعوت کنند. میتوانند تا مدتی این گزینه را روشن کنند.
          </p>
          <h2 class="text-lg font-bold mt-3">پیشنهاد تنظیمات توسط سازنده</h2>
          <p>
            بهتر است تعداد دعوت دوباره کمتر از 2 ماه نباشد. اگر تصمیم به فعال سازی دعوت
            دوباره دارید بهتر است مقدار آن بین 90 تا 365 روز باشد.
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR lock time -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('lock_time', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-swing-time-96.png" class="inline-block h-20" />
          قفل زماندار
        </div>

        <MnrCheckSlider v-model:checked="setting.lock_time" class="mt-3">
          قفل زماندار اول
        </MnrCheckSlider>
        <div class="grid xl:grid-cols-3 grid-cols-2 gap-3">
          <MnrText
            v-model="setting.lock_time_start"
            title="شروع از"
            type="time"
            :disabled="!setting.lock_time"
          />
          <MnrText
            v-model="setting.lock_time_end"
            title="پایان"
            type="time"
            :disabled="!setting.lock_time"
          />
        </div>
        <MnrCheckSlider v-model:checked="setting.lock_time_2" class="mt-3">
          قفل زماندار دوم
        </MnrCheckSlider>
        <div class="grid xl:grid-cols-3 grid-cols-2 gap-3">
          <MnrText
            v-model="setting.lock_time_start_2"
            title="شروع از"
            type="time"
            :disabled="!setting.lock_time_2"
          />
          <MnrText
            v-model="setting.lock_time_end_2"
            title="پایان"
            type="time"
            :disabled="!setting.lock_time_2"
          />
        </div>

        <template #help>
          <p class="mt-2">
            از این قسمت میتوانید یک یا دو ساعت را برای قفل خودکار گروه مشخص کنید.
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR join force -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('join_force', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-rearrange-96.png" class="inline-block h-20" />
          عضویت اجباری در کانال یا گروه
        </div>
        <div class="p-3 bg-blueGray-50 border border-blueGray-200 mt-4">
          <p>
            دقت کنید برای این که این گزینه درست کار کند باید ربات
            <a href="https://t.me/mnrg1_joinBot" class="text-sky-500">@mnrg1_joinBot</a>
            را در مبدا و مقصد ادمین کنید.
          </p>
          <p
            class="mt-3 p-3 bg-green-300"
            v-if="join_main?.result?.status == 'administrator'"
          >
            ربات در مبدا ادمین است
          </p>
          <p class="mt-3 p-3 bg-red-300" v-else>ربات در مبدا ادمین نیست</p>
          <p
            class="mt-3 p-3 bg-green-300"
            v-if="join_target?.result?.status == 'administrator'"
          >
            ربات در مقصد ادمین است
          </p>
          <p class="mt-3 p-3 bg-red-300" v-else>ربات در مقصد ادمین نیست</p>
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            title="عضویت اجباری"
            v-model="setting.join_force"
            :options="
              L_.filter(options, (o) => {
                return ['off', 'delete', 'alert'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="عضویت اجباری برای"
            v-model="setting.join_force_type"
            :options="
              L_.filter(options, (o) => {
                return ['pm', 'link'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
            :disabled="setting.join_force == 'off'"
          ></MnrSelect>
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrText
            v-model="setting.join_text"
            title="نام کانال یا گروه"
            placeholder="مثال: کیف و کفش میلاد"
            :disabled="setting.join_force == 'off'"
          />
          <MnrText
            v-model="setting.join_username"
            inputDir="ltr"
            title="ID عددی یا ID گروه یا کانال"
            placeholder="مثال: mnrtab / -1001391796304"
            :disabled="setting.join_force == 'off'"
          />
          <MnrText
            v-model="setting.join_link"
            inputDir="ltr"
            title="لینک گروه یا کانال"
            placeholder="مثال: https://t.me/joinchat/JpdUR0gavQ6l..."
            :disabled="setting.join_force == 'off'"
          />
        </div>

        <template #help>
          <p class="mt-2">
            از این قسمت می‌توانید مشخص کنید کاربران برای ارسال پیام در گروه یا کانال دیگری
            عضو شوند
          </p>
          <p class="mt-5">
            دقت کنید برای این که این گزینه درست کار کند باید ربات
            <a href="https://t.me/mnrg1_joinBot" class="text-sky-500">@mnrg1_joinBot</a>
            را در مبدا و مقصد ادمین کنید.
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR pm limit -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('limit_pm', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-chat-room-96.png" class="inline-block h-20" />
          محدودیت ارسال پیام
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            title="محدودیت"
            v-model="setting.limit_pm"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="نوع محدودیت"
            v-model="setting.limit_pm_type"
            :options="
              L_.filter(options, (o) => {
                return ['hour', 'day'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
            :disabled="setting.limit_pm == 'off'"
          ></MnrSelect>
        </div>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrNumber
            v-model="setting.limit_count"
            title="تعداد پیام"
            :min="1"
            :max="20"
            :disabled="setting.limit_pm == 'off'"
          />
          <MnrNumber
            v-model="setting.limit_time"
            title="در چند ساعت"
            :min="1"
            :max="24"
            v-show="setting.limit_pm_type == 'hour'"
            :disabled="setting.limit_pm == 'off'"
          />
        </div>

        <template #help>
          <p class="mt-2">
            می‌توانید مشخص کنید که کاربران چه تعداد پیام رو در چه محدوده زمانی می‌توانند
            ارسال کنند. همچنین میتوانید مشخص کنید این کار بر اساس یک شبانه روز باشد و از
            12 شب شروع شود. یا بر اساس ساعت دلخواه
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR spam -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('spam', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-chat-96.png" class="inline-block h-20" />
          اسپم و پیام تکراری
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            v-model="setting.no_spam"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
        </div>

        <template #help>
          <p class="mt-2">
            در این قسمت می‌توانید مشخص کنید اگر کاربری یک پیام را به صورت تکراری ارسال کرد
            آن پیام حذف شود.
          </p>

          <h2 class="text-lg font-bold mt-3">پیشنهاد تنظیمات توسط سازنده</h2>
          <p>
            اگر گروه شما تبلیغاتی است بهتر است این گزینه خاموش باشد. این مورد در گروه های
            تخصصی و پرسش و پاسخ بهتر است روی حذف پیام یا اخطار و حذف پیام باشد
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR pm manage -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('text_manage', $event)"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-request-service-96.png"
            class="inline-block h-20"
          />
          مدیریت متن و طول پیام
        </div>
        <div class="grid xl:grid-cols-3 grid-cols-2 gap-3 mt-3">
          <MnrNumber
            v-model="setting.min_text"
            title="کمترین تعداد کاراکتر در متن"
            hint="0 = غیر فعال"
            :min="0"
            :max="4000"
          />
          <MnrNumber
            v-model="setting.max_text"
            title="بیشترین تعداد کاراکتر در متن"
            hint="0 = غیر فعال"
            :min="0"
            :max="4000"
          />
        </div>
        <div class="grid xl:grid-cols-3 grid-cols-2 gap-3 mt-3">
          <MnrNumber
            v-model="setting.min_caption"
            title="کمترین تعداد کاراکتر در کپشن"
            hint="0 = غیر فعال"
            :min="0"
            :max="4000"
          />
          <MnrNumber
            v-model="setting.max_caption"
            title="بیشترین تعداد کاراکتر در کپشن"
            hint="0 = غیر فعال"
            :min="0"
            :max="4000"
          />
        </div>
        <div class="grid xl:grid-cols-3 grid-cols-2 gap-3 mt-3">
          <MnrSelect
            title="فیلتر کلمات نامناسب"
            v-model="setting.bad_word"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrSelect
            title="فیلتر کلمات دلخواه"
            v-model="setting.filter"
            :options="
              L_.filter(options, (o) => {
                return [
                  'off',
                  'delete',
                  'alert',
                  'ban',
                  'kick',
                  'ban_ever',
                  'kick_ever',
                ].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
        </div>
        <div class="grid xl:grid-cols-3 grid-cols-2 gap-3 mt-3">
          <MnrMultiText
            v-model="filters.filterLow"
            title="فیلتر ساده"
            placeholder="با اینتر جدا کنید"
            hint="کلمات را نوشته و اینتر بزنید"
            :disabled="setting.filter == 'off'"
          ></MnrMultiText>
          <MnrMultiText
            v-model="filters.filterAvg"
            title="فیلتر متوسط"
            placeholder="با اینتر جدا کنید"
            hint="کلمات را نوشته و اینتر بزنید"
            :disabled="setting.filter == 'off'"
          ></MnrMultiText>
          <MnrMultiText
            v-model="filters.filterPro"
            title="فیلتر پیشرفته"
            placeholder="با اینتر جدا کنید"
            hint="کلمات را نوشته و اینتر بزنید"
            :disabled="setting.filter == 'off'"
          ></MnrMultiText>
        </div>

        <template #help>
          <h2 class="text-lg font-bold">تعداد کاراکتر</h2>
          <p class="mt-2">
            میتوانید مشخص کنید که متن یا کپشن چه تعداد حرف یا کاراکتر داشته باشند.
          </p>
          <h2 class="text-lg font-bold mt-3">فیلتر کلمات</h2>
          <p class="mt-2">
            می‌توانید از فیلتر پیشفرض استفاده کنید یا فیلتر دلخواه خود را اضافه کنید. دقت
            کنید هرچه از فیلتر پیشرفته تر استفاده کنید ضریب خطا بیشتر می‌شود. پس ابتدا از
            فیلتر ساده، سپس متوسط و اگر جواب نگرفتید از پیشرفته استفاده کنید
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR warning -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('warning', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-error-96.png" class="inline-block h-20" />
          اخطار به کاربران
        </div>

        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <MnrSelect
            title="سیستم اخطار"
            v-model="setting.warning"
            :options="
              L_.filter(options, (o) => {
                return ['off', 'ban', 'kick', 'ban_ever', 'kick_ever'].includes(o.value);
              })
            "
            placeholder="انتخاب کنید"
          ></MnrSelect>
          <MnrNumber
            v-model="setting.warning_limit"
            :disabled="setting.warning == 'off'"
            title="تعدا اخطار"
            :min="1"
            :max="20"
            placeholder="تعداد را وارد کنید"
            :msg="setting.warning_limit < 3 ? 'کمتر از 3 پیشنهاد نمی‌شود' : null"
          />
          <MnrNumber
            v-model="setting.warning_count_time"
            :disabled="setting.warning == 'off'"
            title="در چند روز"
            :min="1"
            :max="5"
            placeholder="تعداد روز را وارد کنید"
          />
        </div>

        <template #help>
          <p class="mt-2">
            سیستم پیشرفته اخطار ربات به شما این امکان را می‌دهد که به کاربران اخطار دهید و
            زمانی که تعداد اخطار های کاربر به تعداد مشخص رسید چه کاری با آن کاربر صورت
            بگیرد. اگر این سیستم خاموش باشد اخطار ها در پیام ها درج نمی‌شود. برای شخصی
            سازی پیام های ربات و اخطار به قسمت پیام های ربات بروید.
          </p>
          <h2 class="text-lg font-bold mt-3">پیشنهاد تنظیمات توسط سازنده</h2>
          <p>
            بهتر است تعداد اخطار کمتر از 10 نباشد. چون در مواردی کاربر تازه وارد از روی نا
            آگاهی اخطار گرفته و ممکن است محدود شود. بهتر است مقدار اخطار را روی محدود شود
            قرار دهید و از با فعال سازی سیستم گزارش در قسمت مدیریت گروه خودتان برای کاربر
            محدود شده تصمیم بگیرید.
          </p>
        </template>
      </UserSettingCard>

      <!-- ANCHOR submit all -->

      <UserSettingCard
        :otherSetting="otherSetting"
        :otherGroup="otherGroup"
        :loading="sending"
        @setSettings="submit('all', $event)"
      >
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-enter-key-96.png" class="inline-block h-20" />
          توجه کنید: ثبت همه
        </div>
        <p>
          شما در هر یک از بخش های بالا می‌توانید تنظیمات آن بخش را ذخیره کنید اما در این
          قسمت میتوانید تنظیمات تمام قسمت ها را به صورت یک جا ذخیره کنید
        </p>
      </UserSettingCard>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

import MnrSelect from "@/components/mnr/MnrSelect.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";
import MnrMultiText from "@/components/mnr/MnrMultiText.vue";

import _ from "lodash";
import UserSettingCard from "@/components/UserSettingCard.vue";
import ErrorBox from "@/components/ErrorBox.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Icon,
    MnrSelect,
    MnrText,
    MnrNumber,
    MnrCheckSlider,
    MnrMultiText,
    UserSettingCard,
    ErrorBox,
    Button,
  },
  data() {
    return {
      errors: [],
      firstLoading: true,
      group: {},
      setting: {},
      otherSetting: [],
      otherGroup: [],
      L_: _,
      sending: false,
      join_main: {},
      join_target: {},
      options: [
        { value: "off", text: "🌑 خاموش" },
        { value: "delete", text: "🌕✂️ حذف پیام" },
        { value: "alert", text: "🌕⚠️ اخطار و حذف پیام" },
        { value: "ban", text: "🌕⭕️ محدود شود" },
        { value: "kick", text: "🌕❌ اخراج شود" },
        { value: "ban_ever", text: "🌕⛔️ برای همیشه محدود شود" },
        { value: "kick_ever", text: "🌕🚷 برای همیشه اخراج شود" },
        { value: "pro", text: "🌕♻️ حذف لینک پیشرفته" },
        { value: "hour", text: "بر اساس ساعت" },
        { value: "day", text: "بر اساس یک شبانه‌روز (24 ساعت)" },
        { value: "pm", text: "برای ارسال پیام" },
        { value: "link", text: "برای ارسال لینک" },
        { value: "in_text", text: "ارسال کننده در متن" },
        { value: "in_key", text: "ارسال کننده در کلید شیشه‌ای" },
      ],
      parts: {
        setting: {
          showAdd: false,
          showChange: false,
          value: Number(this.$route.params.settingId),
        },
        // addMember:{

        // }
      },
      filters: {
        filterLow: [],
        filterAvg: [],
        filterPro: [],
      },
      filters_name: {
        filterLow: [],
        filterAvg: [],
        filterPro: [],
      },
    };
  },
  watch: {
    filters: {
      handler(val) {
        this.setting.filter_json = val;
      },
      deep: true,
    },
    filters_name: {
      handler(val) {
        this.setting.filter_name_json = val;
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
    this.getJoinBot();
  },
  methods: {
    getData() {
      let $this = this;
      $this.firstLoading = true;
      $this.$axios
        .get(
          "/api/group-user-setting/" +
            $this.$route.params.groupId +
            "/" +
            $this.parts.setting.value,
          {
            params: {
              id: $this.$store.state.user.id,
            },
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.setting = response.data.setting;
          $this.otherSetting = response.data.otherSetting;
          $this.otherGroup = response.data.otherGroup;

          $this.filters = {
            filterLow: $this.setting.filter_json?.filterLow || [],
            filterAvg: $this.setting.filter_json?.filterAvg || [],
            filterPro: $this.setting.filter_json?.filterPro || [],
          };
          $this.filters_name = {
            filterLow: $this.setting.filter_name_json?.filterLow || [],
            filterAvg: $this.setting.filter_name_json?.filterAvg || [],
            filterPro: $this.setting.filter_name_json?.filterPro || [],
          };
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
        });
    },
    getJoinBot() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get(
          "/api/group-user-setting-join-bot/" +
            $this.$route.params.groupId +
            "/" +
            $this.parts.setting.value,
          {
            params: {
              id: $this.$store.state.user.id,
            },
          }
        )
        .then(function (response) {
          $this.join_main = response.data.join_main;
          $this.join_target = response.data.join_target;
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    changeSetting() {
      // console.log("change");
      this.$router.replace({ params: { settingId: this.parts.setting.value } });
      this.getData();
    },
    submit(update, id = this.$route.params.settingId) {
      // console.log(update, id);
      // return;
      let $this = this;
      $this.sending = update;
      // console.log(id, update);
      $this.errors = [];
      $this.$axios
        .put(
          "/api/group-user-setting/" +
            $this.$route.params.groupId +
            "/" +
            $this.parts.setting.value,
          {
            id: $this.$store.state.user.id,
            update: update,
            settingsId: id,
            setting: $this.setting,
          }
        )
        .then(function (response) {
          $this.$notify({ text: "تغییرات ذخیره شد", type: "success" });
          if (update == "join_force" || update == "all") {
            $this.getJoinBot();
          }
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.$notify({ text: "خطا: تغییرات ذخیره نشد", type: "error" });
        })
        .then(function () {
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
